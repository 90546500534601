import { css, useTheme, FlattenSimpleInterpolation } from 'styled-components';

export const mq = (breakpoint: number) => (
  literals: TemplateStringsArray,
  ...placeholders: any[]
): FlattenSimpleInterpolation =>
  css`
    @media (min-width: ${breakpoint}px) {
      ${css(literals, ...placeholders)}
    }
  `;

export const resetButtonStyles = () => css`
  width: auto;
  margin: 0;
  padding: 0;
  overflow: visible;
  border: none;
  outline: none;
  background: transparent;
  line-height: normal;
  -webkit-appearance: none;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
`;

export const overlay = () => css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const center = () => `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const hexToRGBArray = (hex: string) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return [r, g, b];
};

export const hexAlpha = (hex: string, alpha: number) => {
  const [r, g, b] = hexToRGBArray(hex);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const elevationWithHover = () => {
  const { shadows } = useTheme();
  return `
    box-shadow: ${shadows.elevation};
    transition: 0.15s box-shadow;

    &:hover {
      box-shadow: ${shadows.elevationStrong}
    }
  `;
};
