import { FC } from 'react';
import { colors } from '../../theme';
import { IconProps } from '.';

const SVG: FC<IconProps> = ({
  fill = colors.black,
  width,
  height,
  viewBox = '0 0 10 5',
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M0 0L5 5L10 0H0Z" fill={fill} />
  </svg>
);

export default SVG;
