import { breakpoints } from '../../theme';
import { Props as TypographyProps } from './Typography';

type OptionalExceptFor<T, TRequired extends keyof T> = Partial<T> & Pick<T, TRequired>;

type FontSizeType = {
  [key in keyof typeof breakpoints | 'xs']: number;
};

type FontSizeTypeXSRequired = OptionalExceptFor<FontSizeType, 'xs'>;

interface Props extends TypographyProps {
  fontSize: FontSizeTypeXSRequired;
}

const styles: { [key: string]: Props } = {
  h1: {
    tag: 'h1',
    fontSize: {
      xs: 24,
      sm: 28,
      md: 32,
      lg: 36,
      xl: 39,
    },
    fontWeight: 'bold',
    lineHeight: 1.3,
  },
  h2: {
    tag: 'h2',
    fontSize: {
      xs: 24,
      sm: 26,
      md: 28,
      lg: 31,
    },
    fontWeight: 'bold',
    lineHeight: 1.3,
  },
  h3: {
    tag: 'h3',
    fontSize: {
      xs: 20,
      sm: 22,
      md: 25,
    },
    fontWeight: 'bold',
    lineHeight: 1.3,
  },
  h4: {
    tag: 'h4',
    fontSize: {
      xs: 16,
      sm: 14,
      md: 18,
    },
    fontWeight: 'bold',
    lineHeight: 1.3,
  },
  subtitle: {
    tag: 'h4',
    fontSize: {
      xs: 18,
      md: 20,
    },
    fontWeight: 'bold',
    lineHeight: 1.3,
  },
  bodyText: {
    tag: 'p',
    fontSize: {
      xs: 16,
    },
    fontWeight: 'normal',
    lineHeight: 1.3,
  },
  detailText: {
    tag: 'p',
    fontSize: {
      xs: 13,
    },
    fontWeight: 'normal',
    lineHeight: 1.3,
  },
};

export default styles;
