import { FC } from 'react';
import { colors } from '../../theme';
import { IconProps } from '.';

const SVG: FC<IconProps> = ({
  fill = colors.black,
  width,
  height,
  viewBox = '0 0 10 13',
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.3335 0.0175781H1.66683C0.933496 0.0175781 0.340163 0.618178 0.340163 1.35224L0.333496 12.0296L5.00016 10.0276L9.66683 12.0296V1.35224C9.66683 0.618178 9.06683 0.0175781 8.3335 0.0175781Z"
      fill={fill}
    />
  </svg>
);

export default SVG;
