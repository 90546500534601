import { FC, useContext } from 'react';
import { colors } from '../../theme';
import Logout from './Logout';
import BookmarkEmpty from './BookmarkEmpty';
import BookmarkFull from './BookmarkFull';
import { ThemeContext } from 'styled-components';
import Triangle from './Triangle';

export const names = ['logout', 'bookmark-empty', 'bookmark-full', 'triangle'] as const;

export type NameType = (typeof names)[number];

type ColorType = keyof typeof colors;

export type IconProps = {
  width?: string;
  height?: string;
  viewBox?: string;
  fill?: string;
};

interface Props extends Omit<IconProps, 'fill'> {
  name: NameType;
  fill?: ColorType;
}

const getFinalDimensions = (width: IconProps['width'], height: IconProps['height']) => {
  let finalWidth = width;
  let finalHeight = height;
  if (!width && !height) {
    finalWidth = finalHeight = '100%';
  }
  return { width: finalWidth, height: finalHeight };
};

const Icon: FC<Props> = props => {
  const themeContext = useContext(ThemeContext);
  const { width, height } = getFinalDimensions(props.width, props.height);
  const finalProps = {
    ...props,
    width,
    height,
    ...(props.fill && { fill: themeContext.colors[props.fill] }),
  };

  switch (props.name) {
    case 'logout':
      return <Logout {...finalProps} />;
    case 'bookmark-empty':
      return <BookmarkEmpty {...finalProps} />;
    case 'bookmark-full':
      return <BookmarkFull {...finalProps} />;
    case 'triangle':
      return <Triangle {...finalProps} />;
  }
};

export default Icon;
