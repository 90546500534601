import { FC } from 'react';
import { colors } from '../../theme';
import { IconProps } from '.';

const SVG: FC<IconProps> = ({
  fill = colors.black,
  width,
  height,
  viewBox = '0 0 19 18',
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.09195 12.59L8.50195 14L13.502 9L8.50195 4L7.09195 5.41L9.67195 8H0.00195312V10H9.67195L7.09195 12.59ZM16.002 0H2.00195C0.891953 0 0.00195312 0.9 0.00195312 2V6H2.00195V2H16.002V16H2.00195V12H0.00195312V16C0.00195312 17.1 0.891953 18 2.00195 18H16.002C17.102 18 18.002 17.1 18.002 16V2C18.002 0.9 17.102 0 16.002 0Z"
      fill={fill}
    />
  </svg>
);

export default SVG;
