export const breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

export const colors = {
  black: '#000000',
  white: '#ffffff',
  darkerGray: '#333333',
  darkGray: '#666666',
  mediumGray: '#cccccc',
  lightGray: '#f0f0f0',
  surfie: '#116688',
  ochre: '#cc7722',
  cosmic: '#704060',
  wasabi: '#889922',
  supernova: '#ffcc00',
};

export const fontWeights = {
  normal: 400,
  bold: 700,
};

export const radii = {
  circle: '50%',
  pill: '9999px',
  button: '4px',
  card: '10px',
};

export const shadows = {
  elevation: '0px 2px 8px rgba(0, 0, 0, 0.18)',
  elevationStrong: '0px 2px 8px rgba(0, 0, 0, 0.3)',
};

export const sizes = {
  maxContentWidth: '1800px',
  headerHeight: '70px',
};
