import { FC } from 'react';
import { colors } from '../../theme';
import { IconProps } from '.';

const SVG: FC<IconProps> = ({
  fill = colors.black,
  width,
  height,
  viewBox = '0 0 10 13',
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.3335 0.015625H1.66683C0.933496 0.015625 0.340163 0.615625 0.340163 1.34896L0.333496 12.0156L5.00016 10.0156L9.66683 12.0156V1.34896C9.66683 0.615625 9.06683 0.015625 8.3335 0.015625ZM8.33313 10.0152L4.9998 8.56189L1.66647 10.0152V1.34856H8.33313V10.0152Z"
      fill={fill}
    />
  </svg>
);

export default SVG;
